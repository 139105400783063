<template>
  <footer class="container-fluid app-footer">
    <div class="container">
      <div class="row">
        <div class="col text-center">&copy; {{ getYear() }} WGTS</div>
      </div>
    </div>
  </footer>
</template>

<script>
import dayjs from 'dayjs';
export default {
  name: 'PageFooter',
  methods: {
    getYear() {
      return dayjs().year();
    },
  },
};
</script>

<style scoped lang="scss"></style>
