<template>
  <div class="off-canvas container-fluid bg-dark" :class="{ active: isOpen }">
    <div class="row my-2">
      <div class="col text-right">
        <button
          class="btn btn-dark"
          type="button"
          aria-label="Toggle navigation"
          @click="closeMenu"
        >
          <font-awesome-icon icon="times" /> Close
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ul class="nav flex-column">
          <li :class="{ 'nav-item': true, active: activePage === 'dashboard' }">
            <router-link
              :class="{ 'nav-link': true, active: activePage === 'dashboard' }"
              to="/dashboard"
              @click="closeMenu"
            >
              Dashboard
            </router-link>
          </li>
          <li
            :class="{ 'nav-item': true, active: activePage === 'campaigns' }"
            v-if="can('index', 'campaigns')"
          >
            <router-link
              :class="{ 'nav-link': true, active: activePage === 'campaigns' }"
              to="/campaigns"
              @click="closeMenu"
            >
              Campaigns
            </router-link>
          </li>
          <li
            :class="{ 'nav-item': true, active: activePage === 'promotions' }"
            v-if="can('index', 'promotions')"
          >
            <router-link
              :class="{ 'nav-link': true, active: activePage === 'promotions' }"
              to="/promotions"
              @click="closeMenu"
            >
              Promotions
            </router-link>
          </li>
          <li
            :class="{ 'nav-item': true, active: activePage === 'pledges' }"
            v-if="can('index', 'pledges')"
          >
            <router-link
              :class="{ 'nav-link': true, active: activePage === 'pledges' }"
              to="/pledges"
              @click="closeMenu"
            >
              Pledges
            </router-link>
          </li>
          <li
            :class="{ 'nav-item': true, active: activePage === 'donors' }"
            v-if="can('index', 'donors')"
          >
            <router-link
              :class="{ 'nav-link': true, active: activePage === 'donors' }"
              to="/donors"
              @click="closeMenu"
            >
              Donors
            </router-link>
          </li>
          <li
            :class="{ 'nav-item': true, active: activePage === 'donations' }"
            v-if="can('index', 'donations')"
          >
            <router-link
              :class="{ 'nav-link': true, active: activePage === 'donations' }"
              to="/donations"
              @click="closeMenu"
            >
              Donations
            </router-link>
          </li>
          <li
            :class="{ 'nav-item': true, active: activePage === 'favorite-comments' }"
            v-if="can('index', 'donations') && can('index', 'favorite_comments')"
          >
            <router-link
              :class="{ 'nav-link': true, active: activePage === 'favorite-comments' }"
              :to="{ name: 'favoriteComments' }"
              @click="closeMenu"
            >
              Favorite Comments
            </router-link>
          </li>
          <div v-if="can('index', 'settings')" class="dropdown-divider"></div>
          <template v-if="canByRole(['admin', 'developer'])">
            <li
              :class="{ 'nav-item': true, active: activePage === 'users' }"
              v-if="can('index', 'users')"
            >
              <router-link
                :class="{ 'nav-link': true, active: activePage === 'users' }"
                :to="{ name: 'users' }"
                @click="closeMenu"
              >
                Manage Users
              </router-link>
            </li>
          </template>
          <li
            :class="{ 'nav-item': true, active: activePage === 'settings' }"
            v-if="can('index', 'settings')"
          >
            <router-link
              :class="{ 'nav-link': true, active: activePage === 'settings' }"
              to="/settings"
              @click="closeMenu"
            >
              Settings
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuOffCanvas',
  computed: {
    activePage() {
      let path = this.$route.path.split('/');
      let activePage = path.pop();

      if (!isNaN(activePage)) {
        activePage = path.pop();
      }

      // console.log('MenuOffCanvas activePage(), path', { activePage, path });

      return activePage;
    },
    isOpen() {
      return this.$store.getters['ui/isMenuOpen'];
    },
  },
  methods: {
    closeMenu() {
      return this.$store.dispatch('ui/closeMenu');
    },
  },
};
</script>

<style scoped></style>
