<template>
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top bg-dark" id="top-navigation">
    <a class="navbar-brand" href="/">
      <!-- eslint-disable-next-line -->
      <img src="@/assets/logo.png" class="img-fluid">
    </a>
    <template v-if="loggedIn">
      <button
        class="btn btn-dark mr-auto"
        type="button"
        aria-label="Toggle navigation"
        @click="openMenu"
      >
        <font-awesome-icon icon="bars" />
      </button>

      <button
        v-if="isDashboard"
        class="btn btn-presentation mr-3"
        type="button"
        aria-label="Disable Dashboard Refresh"
        @click="toggleDashboardRefresh(!dashboardRefresh)"
      >
        <span :class="{ 'fa-layers': !dashboardRefresh }" class="fa-fw">
          <font-awesome-icon v-if="!dashboardRefresh" icon="slash" />
          <font-awesome-icon icon="sync-alt" />
        </span>
        {{ dashboardRefresh ? 'Disable' : 'Enable' }} Refresh
      </button>

      <div v-if="false && canByRole(['developer'])" class="dropdown mr-3">
        <button
          class="btn btn-presentation dropdown-toggle"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <font-awesome-icon icon="stopwatch" />
          Refresh Rate
        </button>
        <div class="dropdown-menu">
          <a
            v-for="rate in refreshIntervals"
            :key="`rate-${rate}s`"
            class="dropdown-item"
            href="#"
            @click="setRefreshRate(rate)"
          >
            {{ rate }}s
          </a>
        </div>
      </div>

      <a class="btn btn-presentation mr-3" target="_blank" :href="cameraLink" v-if="hasCamera">
        <font-awesome-icon icon="phone-square" /> Phone Lines
      </a>

      <router-link class="btn btn-presentation mr-3" :to="{ name: 'presentation' }">
        <font-awesome-icon icon="expand" /> Presentation Mode
      </router-link>

      <div class="dropdown">
        <button
          class="btn btn-dark ml-2"
          type="button"
          id="dropdownUserMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <font-awesome-icon icon="user-circle" />
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownUserMenuButton">
          <router-link class="dropdown-item" :to="{ name: 'profile' }">My Profile</router-link>
          <a class="dropdown-item" href="#" @click.prevent="logout">Logout</a>
        </div>
      </div>
    </template>
  </nav>
</template>

<script>
import * as _ from 'lodash';
export default {
  name: 'Navigation',
  computed: {
    activePage() {
      let path = this.$route.path.split('/');
      let activePage = path.pop();
      if (!isNaN(activePage)) {
        activePage = path.pop();
      }
      // console.log('components Navigation computed :activePage, :path', { activePage, path });
      return activePage;
    },
    loggedIn() {
      return this.$store.getters['auth/loggedIn'];
    },
    activeUser() {
      return this.$store.getters['users/getActiveUser'];
    },
    firstName() {
      return this.activeUser.first ?? 'there';
    },
    hasCamera() {
      if (this.can('view', 'settings') && this.can('view', 'camera')) {
        const camera = this.$store.getters['settings/get']('phoneLineCamera');
        return _.isObject(camera) && _.isObject(camera.meta) && camera.meta.ip.length > 0;
      }

      return false;
    },
    cameraLink() {
      const camera = this.$store.getters['settings/get']('phoneLineCamera');
      const subFolder = camera.meta.subFolder ? `${camera.meta.subFolder}/` : '';
      return `http://${camera.meta.ip}/${subFolder}min.php`;
    },
    dashboardRefresh() {
      return this.$store.getters['ui/isDashboardRefresh'];
    },
    isDashboard() {
      return this.activePage === 'dashboard';
    },
  },
  data() {
    return {
      error: null,
      errorRoles: null,
      isLoading: false,
      isLoadingRoles: false,
      refreshIntervals: [0, 1, 5, 10, 20, 30, 40, 50, 60],
    };
  },
  created() {
    if (this.loggedIn) {
      // console.log('components Navigation created() :loggedIn', this.loggedIn);
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      // #TODO lock the page while loading
      this.error = null;
      this.errorRoles = null;
      this.isLoading = true;
      this.isLoadingRoles = true;

      // console.log('components Navigation methods fetchData() users/getMe before');
      this.$store
        .dispatch('users/getMe')
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        })
        .then(() => {
          this.isLoading = false;

          // if user has `Donations` role, then redirect them to that page
          // console.log('Navigation methods fetchData()', this.hasRole('donations'), this.myRoles);
          if (
            this.hasRole('donations') &&
            this.$route.name === 'dashboard' &&
            this.$route.name !== 'donations'
          ) {
            this.$router.push({ name: 'donations' });
          }
        });

      // #TODO We should not be using the ID number directly.
      this.$store.dispatch('settings/get', { id: 1 });

      // console.log('components Navigation methods fetchData() roles/getAll before');
      /*this.$store
        .dispatch('roles/getAll', {})
        .catch((error) => {
          this.errorRoles = this.$errorProcessor(error);
        })
        .then(() => {
          this.isLoadingRoles = false;
        });*/
    },
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({ name: 'login' });
      });
    },
    openMenu() {
      return this.$store.dispatch('ui/toggleMenu');
    },
    toggleDashboardRefresh() {
      return this.$store.dispatch('ui/disableRefresh');
    },
    setRefreshRate(rate) {
      this.$store.dispatch('ui/setRefreshRate', rate * 1000);
    },
  },
};
</script>

<style></style>
