<template>
  <div id="app" class="authenticated">
    <MenuOffCanvas />

    <!-- Top fixed navigation -->
    <Navigation class="top-nav" :class="{ active: isOpen }" />

    <!-- Body -->
    <router-view class="body-router-view" :class="{ active: isOpen }" />

    <!-- Page Footer -->
    <PageFooter />

    <div v-if="!isProduction" class="environment">
      <span>{{ environment }}</span>
    </div>
  </div>
</template>

<script>
import Navigation from 'components/Navigation';
import PageFooter from 'components/PageFooter';
import MenuOffCanvas from 'components/MenuOffCanvas';

export default {
  name: 'Authenticated',
  components: {
    MenuOffCanvas,
    Navigation,
    PageFooter,
  },
  computed: {
    environment() {
      return process.env.NODE_ENV;
    },
    rememberMe() {
      return this.$store.getters['auth/getRememberMe'];
    },
    isProduction() {
      // console.log('Authenticated computed isProduction()', {
      //   process,
      //   env: process.env,
      //   node_env: process.env.NODE_ENV,
      // });
      return process.env.NODE_ENV === 'production';
    },
    isOpen() {
      return this.$store.getters['ui/isMenuOpen'];
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin deactivate-click-event {
  pointer-events: none;
  opacity: 0.7;
}
.environment {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  z-index: 1035;
  background-image: repeating-linear-gradient(-55deg, #000, #000 20px, #ffb101 20px, #ffb101 40px);

  span {
    display: none;
  }
}
.body-router-view {
  &.active {
    @include deactivate-click-event;
  }
}
.top-nav {
  &.active {
    @include deactivate-click-event;
  }
}
</style>
